import logo from './logo.svg';
import './App.css';
import Header from './component/header/header';
import DentalPage from './component/dentalPage/dentalPage';
import Features from './component/features/features';
import OurService from './component/ourService/ourService';
import Video from './component/video/video';
import Contacts from './component/contacts/contacts';
import MapLocations from './component/locations/mapLocations';
import Footer from './component/footer/footer';

function App() {
  return (
    <div className="App">
      <div className="">
        <Header/>
        <DentalPage/>
        <Features/>
        <OurService/>
        <Video/>
        <Contacts/>
        <MapLocations/>
        <Footer/>
      </div>
    </div>
  );
}

export default App;
