/* eslint-disable react/no-unescaped-entities */
//@ts-check
import React, { useEffect, useState } from "react";
import "./video.scss";
import { GetTeamData } from "../api/getApi";
import PopUpPage from "../popUp/popUp";

const Video = () => {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const [teamData, setTeamData] = useState(null);

  useEffect(() => {
    GetTeamData().then((response) => {
      setTeamData(response.data);
    });
  }, []);
  return (
    <>
      <div className="video-section section-top">
        <div className="container">
          <div className="text-center title-center">
            <h2 className="main-title">
              All encompassing care, a fresh approach to dentistry
            </h2>
            <div className="title-line"></div>
          </div>
          <div className="frame-box">
            <iframe
              src="https://www.youtube.com/embed/AqJIsgnCNEc"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen={false}
            ></iframe>
          </div>
          <div className="center-align">
            <div className="row middle mt-60">
              <div className="col-lg-5 col-md-5 col-sm-12">
                {teamData?.team?.map((item, index) => {
                  return (
                    <div className="shadow-wrapper" key={index}>
                      <img
                        className="video-img"
                        src={item.image.url}
                        alt="service"
                      />
                      <div className="box-shadow">
                        <h6 className="main-title text-uppercase">
                          {" "}
                          {item.name}
                        </h6>
                        <p className="content text-uppercase">
                          {item.position}
                        </p>
                        <button
                          className="main-btn btn-radius"
                          type="button"
                          onClick={handleShow}
                        >
                          Book an appointment
                        </button>
                          <PopUpPage
                            show={show}
                            hide={handleClose}
                            modalHead={"Appointment Heading"}
                            close={"Submit"}
                          />
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="col-lg-7 col-md-7 col-sm-12">
                <div className="align-block">
                  <h1 className="main-title">Meet Our Expert</h1>
                  <div className="title-line"></div>
                  <div className="pt-50">
                    <p className="content">
                      Lorem Ipsum has been the industry's standard dummy text
                      ever since the 1500s, when an unknown printer took a
                      galley of type and scrambled it to make a type specimen
                      book. It has survived not only five centuries.
                    </p>
                  </div>
                  <button className="main-btn btn-radius" type="button">
                    Learn More
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Video;
