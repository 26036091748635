import Axios from "axios";

export const GetServiceDetail = () => {
    return Axios.get("https://run.mocky.io/v3/3d1bb8cc-0ac5-469f-8b76-74b157341fd6");
};
export const GetTeamData = () => {
    return Axios.get("https://run.mocky.io/v3/bdf3e572-9c69-4038-9272-91c9e2419fbc");
};


