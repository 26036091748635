import React, { useState, useEffect } from "react";
import Rating from "../rating/rating.js";
import "./ourService.scss";
import { GetServiceDetail } from "../api/getApi";
import PopUpPage from "../popUp/popUp.js";

const OurService = () => {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const [serviceDetail, setServiceDetail] = useState([]);

  useEffect(() => {
    GetServiceDetail().then((response) => {
      setServiceDetail(response.data);
    });
  }, []);

  return (
    <>
      <div className="service-section section-top">
        <div className="container">
          <div className="text-center">
            <h2 className="main-title title-center">Our Services</h2>
            <div className="title-line"></div>
          </div>
          <div className="row">
            {serviceDetail?.service?.map((item, index) => {
              return (
                <div className="col-lg-3 col-md-4 col-sm-6 hide" key={index}>
                  <div className="price-block">RS. {item.price}</div>
                  <div className="service-img">
                    <div className="img-overlay"></div>
                    <img src={item.image.url} alt="image" />
                    <h6 className="text-overlay">{item.name}</h6>
                  </div>
                </div>
              );
            })}
            {/* <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="service-img">
                  <div className="img-overlay"></div>
                  <img
                    src="https://493071-1777511-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2020/02/saludo-dental.jpg"
                    alt="service"
                  />
                  <h6 className="text-overlay">Dental Bonding</h6>
                  <div></div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="service-img">
                  <div className="img-overlay"></div>
                  <img
                    src="https://493071-1777511-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2020/02/saludo-dental.jpg"
                    alt="service"
                  />
                  <h6 className="text-overlay">Dental Bonding</h6>
                  <div></div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="service-img">
                  <div className="img-overlay"></div>
                  <img
                    src="https://493071-1777511-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2020/02/saludo-dental.jpg"
                    alt="service"
                  />
                  <h6 className="text-overlay">Dental Bonding</h6>
                  <div></div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="service-img">
                  <div className="img-overlay"></div>
                  <img
                    src="https://493071-1777511-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2020/02/saludo-dental.jpg"
                    alt="service"
                  />
                  <h6 className="text-overlay">Dental Bonding</h6>
                  <div></div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="service-img">
                  <div className="img-overlay"></div>
                  <img
                    src="https://493071-1777511-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2020/02/saludo-dental.jpg"
                    alt="service"
                  />
                  <h6 className="text-overlay">Dental Bonding</h6>
                  <div></div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="service-img">
                  <div className="img-overlay"></div>
                  <img
                    src="https://493071-1777511-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2020/02/saludo-dental.jpg"
                    alt="service"
                  />
                  <h6 className="text-overlay">Dental Bonding</h6>
                  <div></div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="service-img">
                  <div className="img-overlay"></div>
                  <img
                    src="https://493071-1777511-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2020/02/saludo-dental.jpg"
                    alt="service"
                  />
                  <h6 className="text-overlay">Dental Bonding</h6>
                  <div></div>
                </div>
              </div> */}
          </div>
          <div className="row mt-60">
            <div className="col-lg-6 col-md-5 col-sm-12">
              <div className="sticky-scroll">
                <h1 className="main-title">
                  Why Our<span className="card-block">Patients Love Us.</span>
                </h1>
                <div className="title-line"></div>
                <div className="pt-50">
                  <p className="content">
                    Lorem Ipsum has been the industry's standard dummy text ever
                    since the 1500s, when an unknown printer took a galley of
                    type and scrambled it to make a type specimen book. It has
                    survived not only five centuries.
                  </p>
                </div>
                <button className="main-btn btn-radius" type="button" onClick={handleShow}>
                  Request Appointment
                </button>
                <PopUpPage
                  show={show}
                  hide={handleClose}
                  modalHead={"Requesting Appointment"}
                  close={"Submit"}
                />
              </div>
            </div>

            <div className="col-lg-6 col-md-7 col-sm-12 alignment1">
              {serviceDetail?.testamonial?.map((first, index) => {
                return (
                  <div className="card-block full-length-box" key={index}>
                    {first.block1?.map((item, index) => {
                      return (
                        <div className="mr-20" key={index}>
                          <div className="box-shadow">
                            <h6 className="main-title text-uppercase">
                              {" "}
                              {item.name}
                            </h6>
                            <div className="ptb-10">
                              <Rating rate={item.rating} />
                            </div>
                            <p className="content">{item.statement}</p>
                          </div>
                          {/* <div className="box-shadow">
                            <h6 className="main-title">{item.name}</h6>
                            <div className="ptb-10">
                              <Rating />
                            </div>
                            <p className="content">{item.statement}</p>
                          </div> */}
                        </div>
                      );
                    })}
                    {first.block2?.map((item, index) => {
                      return (
                        <div className="mr-20" key={index}>
                          <div className="box-shadow">
                            <h6 className="main-title text-uppercase">
                              {item.name}
                            </h6>
                            <div className="ptb-10">
                              <Rating rate={item.rating} />
                            </div>
                            <p className="content">{item.statement}</p>
                          </div>
                          {/* <div className="box-shadow">
                        <h6 className="main-title">{item.name}</h6>
                        <div className="ptb-10">
                          <Rating />
                        </div>
                        <p className="content">{item.statement}</p>
                      </div> */}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurService;
