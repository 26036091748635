import React,{useState} from "react";
import "./dentalPage.scss";
import { ToastContainer } from "react-toastify";

function DentalPage(){
    return(
        <>
        <div className="dental-page mb-30">
            <div className="img-overlay"></div>
            <div className="breadcrumb-content">
                <div className="bg-img"></div>
                <div className="dental-text">
                    <div className="text-content">
                        <h3 className="mb-0 small-txt">Welcome to</h3>
                        <h1 className="mb-0 large-txt">Supreme Dental Care</h1>
                        <h3 className="small-txt">in Banepa</h3>
                    <div className="title-line"></div>
                    </div>
                    <h3 className="txt">Because your smile is a passon.</h3>
                    <div className="mtb-20">
                        <button className="main-btn btn-radius" type="button">
                            Schedule you visit
                        </button>
                        <button className="transparent-btn btn-radius" type="button">
                            Call or Text(011)1111111
                        </button>

                    </div>
                </div>
            </div>
            <div className="container section-top">
            </div>
        </div>
        </>
    )
}

export default DentalPage;
