import React, { useState } from "react";
// import "./location.scss";

function MapLocations() {
  return (
    <>
      <div className="location section-top">
        <div className="container">
          <div className="map-area">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4203.503847495263!2d85.52028547065164!3d27.633456136892104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb0f33b1a23b53%3A0xe8ec0b92bdf38a54!2sBanepa!5e0!3m2!1sen!2snp!4v1631706861375!5m2!1sen!2snp"
              width="100%"
              height="450"
              frameBorder="0"
              allowFullScreen=""
              loading="lazy"
              style={{borderRadius: "10px"}}
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
}

export default MapLocations;
