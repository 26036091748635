import React, { useState } from "react";
import "./features.scss";

function Features() {
  return (
    <>
      <div className="feature-section section-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="sticky-scroll">
                <h1 className="main-title">
                  Hello! We Are Supreme
                  <span className="card-block">Dental Care.</span>
                </h1>
                <div className="title-line"></div>
                <div className="pt-50">
                  <p className="content">
                    Lorem Ipsum has been the industry's standard dummy text ever
                    since the 1500s, when an unknown printer took a galley of
                    type and scrambled it to make a type specimen book. It has
                    survived not only five centuries.
                  </p>
                  <p className="content">
                    Lorem Ipsum has been the industry's standard dummy text ever
                    since the 1500s, when an unknown printer took a galley of
                    type and scrambled it to make a type specimen book.
                  </p>
                  <p className="content">
                    Lorem Ipsum has been the industry's standard dummy text ever
                    since the 1500s, when an unknown printer took a galley of
                    type and scrambled it to make a type specimen book. It has
                    survived not only five centuries.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="border-top">
                    <div className="mtb-40">
                      <p className="circle">
                        <i class="bi bi-clock"></i>
                      </p>

                      <h6 className="text-color blue">On time, anytime</h6>
                      <p className="content">
                        Lorem Ipsum has been the industry's standard dummy text
                        ever since the 1500s, when an unknown printer took a
                        galley of type and scrambled it to make a type specimen
                        book.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="border-top">
                    <div className="mtb-40">
                      <p className="circle green">
                        <i class="bi bi-cash"></i>
                      </p>
                      <h6 className="text-color text-color-green">
                        Easy to understand billing
                      </h6>
                      <p className="content">
                        Lorem Ipsum has been the industry's standard dummy text
                        ever since the 1500s, when an unknown printer took a
                        galley of type and scrambled it to make a type specimen
                        book.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="border-top">
                    <div className="mtb-40">
                      <p className="circle orange">
                        <i class="bi bi-emoji-smile"></i>
                      </p>
                      <h6 className="text-color text-color-orange">
                        Modern care with a smile
                      </h6>
                      <p className="content">
                        Lorem Ipsum has been the industry's standard dummy text
                        ever since the 1500s, when an unknown printer took a
                        galley of type and scrambled it to make a type specimen
                        book.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Features;
