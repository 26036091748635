import React,{useState} from "react";
import ReactStars from "react-rating-stars-component";

const ratingChanged = (newRating) => {
    console.log(newRating);
  };

function Rating(props){
    return(
        <>
          <ReactStars
            count={5}
            onChange={ratingChanged}
            size={24}
            activeColor="#ffd700"
            edit= {false}
            value={props.rate}
        />
        </>
    )
}

export default Rating;